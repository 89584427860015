import $ from "jquery";
globalThis.jQuery = $;

// GENERAL STYLING
import './styles.scss';

// FONT AWESOME
import { library, dom  } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import {faArrowUpRight, faArrowDown, faCircleCheck, faArrowRight} from '@fortawesome/pro-regular-svg-icons'
import {faGear, faBolt, faWrench, faHome, faChevronDown, faXmark, faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons'
library.add(faInstagram, faFacebookF, faYoutube, faLinkedinIn, faArrowUpRight, faArrowDown, faGear, faBolt, faWrench, faHome, faCircleCheck, faChevronDown, faArrowRight, faXmark, faMagnifyingGlass);
dom.watch();

// SLICK SLIDER
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';


// AOS
// import AOS from 'aos';
// import 'aos/dist/aos.css';


(function ($) {

  $(function () {

    'use strict';

    // DOM ready, take it away

    // MOBILE MENU submenu
    $('<div class="subarrow"><i class="fa-regular fa-arrow-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
    $('.mobile-menu .subarrow').on('click', function () {
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
      } else {
        $(this).parent().addClass('active');
      }
    });

    


      // ARROWS
    $('header li.menu-item-has-children > a').append('<div class="subarrow"><i class="fa-regular fa-arrow-down"></i></div>');
    $('.btn-secondary, .btn-outline').append('<div class="btn-arrow"><i class="fa-regular fa-arrow-up-right"></i></div>');
    $('.sub-menu li > a').prepend('<i class="fa-regular fa-arrow-right mr-3 text-lg"></i>');

    // Home icon
    $('.menu-home > a').append('<div class="home"><i class="fa-solid fa-house"></i></i></div>');


      // SEARCH
      // ===============================================
      $('body').on("mouseup", function(e) {
          var scontainer = $(".search-btn, .top-search");
          // If the target of the click isn't the container
          if(!scontainer.is(e.target) && scontainer.has(e.target).length === 0){
              $('.top-search, header .search-btn').removeClass('active');
          }
      });

      $('header .search-btn').on('click', function (e) {
          e.preventDefault();

          if ($(this).hasClass('active')) {
              $(this).removeClass('active');
              $('.top-search').removeClass('active');
          } else {
              $(this).addClass('active');
              $('.top-search').addClass('active');
          }
      });


    // MOBILE TOGGLE
    // ===============================================

    $('.menu-toggle').on('click', function () {
      if ($('.mobile-menu').hasClass('active')) {
        $('.mobile-menu, .page-overlay').removeClass('active');
        $('body').removeClass('overflow-hidden');
      } else {
        $('.mobile-menu, .page-overlay').addClass('active');
        $('body').addClass('overflow-hidden');
      }
    });
    $('.page-overlay').on('click', function () {
      $('.mobile-menu, .page-overlay').removeClass('active');
      $('body').removeClass('overflow-hidden');
    });

    $('.mobile-menu ul li a, .header .logo').on('click', function () {
      $('body').removeClass('overflow-hidden');
    });

    // Close mobile menu on click
    $(document).click(function (event) {
      var $target = $(event.target);
      if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
        $('.mobile-menu').removeClass('active');
        $('body').removeClass('overflow-hidden');
      }

    });


    // Page overlay on hover menu
    $('header li.menu-item-has-children > a').hover(
      function() {
        $('.page-overlay').addClass('active');
      }, 
      function() {
        var $submenu = $(this).siblings('header .sub-menu');
        if (!$submenu.is(':hover')) {
          $('.page-overlay').removeClass('active');
        }
      }
    );
  
    $('header .sub-menu').hover(
      function() {
        $('.page-overlay').addClass('active');
      }, 
      function() {
        var $parentLink = $(this).closest('header li.menu-item-has-children').children('a');
        if (!$parentLink.is(':hover')) {
          $('.page-overlay').removeClass('active');
        }
      }
    );


    // Scrollbar calc for tailwind breakout
    // ===============================================
    $('html').css('--twcb-scrollbar-width', (window.innerWidth - $('html').width()) + "px");

    
    //  sliders
    $('.companies-slider').slick({
      slidesToShow: 6,
      slidesToScroll: 3,
      infinite: false,
      dots: true,
      arrows: false,
      appendDots: $('.companies-dots'),
      responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                }
            },
            {
              breakpoint: 991,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 2,
              }
          },
          {
            breakpoint: 676,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
          },
          {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
          },
        ]
      });

      $('.school-slider').slick({
        slidesToShow: 1.5,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: false,
        mobileFirst: true,
        appendDots: $('.school-dots'),
        responsive: [
            {
                breakpoint: 1280,
                settings: "unslick"
            },
              {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3.5
                }
            },
            {
              breakpoint: 676,
              settings: {
                  slidesToShow: 2.5
              }
            },
            {
              breakpoint: 500,
              settings: {
                  slidesToShow: 1.5
              }
            },
          ]
        });

        $('.gallery-slider').slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
          appendDots: $('.gallery-dots'),
          responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.2
                }
            }
          ]
        });


      $('.testimonials-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: false,
        appendDots: $('.testimonials-dots'),
        responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1
              }
          }
        ]
      });

      $('.similar-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: false,
        appendDots: $('.similar-dots'),
        responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1
              }
          }
        ]
      });

      $('.similar-sb-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
        arrows: false,
        appendDots: $('.similar-sb-dots'),
        responsive: [
          {
              breakpoint: 767,
              settings: {
                  slidesToShow: 1
              }
          }
        ]
      });
  
  
    // HEADER SCROLLED
    // ===============================================
    checkScroll();
      $(window).scroll(function () {
        checkScroll();
    });
  
    function checkScroll() {
        var scroll = $(window).scrollTop();
        if (scroll >= 20) {
            $("header, body").addClass("scrolled");
        } if (scroll < 10) {
          $("header, body").removeClass("scrolled");
        }
      }

    // AOS.init({
    //   duration: 400,
    //   offset: 0, // offset (in px) from the original trigger point
    //   once: true, // whether animation should happen only once - while scrolling down
    //   anchorPlacement: 'top-bottom', // define where the AOS animations will be triggered
    // });
    // window.addEventListener('load', AOS.refresh);


  });

})(jQuery);